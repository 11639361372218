import React from "react";
import { Location } from "@reach/router";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Testimonials from "../components/testimonials";

function AboutPage() {
  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title="About"
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `business`]}
          />
        )}
      </Location>

      <section className="mt-8 flex md:flex-row flex-col">
        <div className="md:w-1/2 w-full">[graphics here]</div>
        <div className="md:w-1/2 w-full">
          <h2 className="text-primary">What is Gortek?</h2>
          <h6>The software company for startup founders</h6>

          <p>
            Gortek is a small software development firm. We specialize in
            designing and building progressive web applications (PWAs) for
            startups and established businesses.
          </p>
          <p>
            We also advocate and coach our startup founder clients to engage in
            Lean Startup methodoligies and assist in customer development long
            before we write a line of code.
          </p>
        </div>
      </section>

      <section className="mt-8 flex md:flex-row flex-col">
        <div className="md:w-1/2 w-full">[graphics here]</div>
        <div className="md:w-1/2 w-full">
          <h2 className="text-primary">Our Story</h2>
          <h6>I'm Tony Sullivan</h6>

          <p>
            Hello, and welcome to Gortek. I'm a software engineer, marketing
            practitioner, and entrepreneur. Gortek is not just my company, it's
            my laboratory and vehicle to work with fellow entrepreneurs to bring
            new products to market.
          </p>
          <p>
            I started out just doing freelance work and joining other teams as
            an additional developer on a sub-contract basis. But now my focus is
            growing Gortek into a highly effective team of professionals.
          </p>
        </div>
      </section>

      <section id="how-we-work" className="mt-8 flex flex-col pt-24">
        <div className="w-full mb-32">
          <h2 className="text-primary">How We Work</h2>
          <p>We believe most people .</p>
        </div>

        <div className="flex md:flex-row flex-col ">
          <div className="md:w-1/2 w-full">
            <h3 className="text-primary">Our Values</h3>
            <p>
              We live by the core values of providing excellent digital
              products, reliable partnerships, cutting edge technology, and to
              make the best in your business.
            </p>
          </div>

          <div className="md:w-1/2 w-full">[graphics here]</div>
        </div>

        <div className="flex md:flex-row flex-col ">
          <div className="md:w-1/2 w-full">[graphics here]</div>

          <div className="md:w-1/2 w-full">
            <h3 className="text-primary">Our Mission</h3>
            <p>
              help as many people as possible start and run successful app based
              businesses.
            </p>
          </div>
        </div>
      </section>

      <section id="our-technology" className="mt-8 flex flex-col pt-24">
        <div className="w-full mb-16">
          <h2 className="text-primary">Our Technology</h2>
          <p>We use the latest technology to stay relevant.</p>
        </div>

        <div className="w-full flex md:flex-row flex-col">
          <div className="md:w-1/3 rounded shadow-lg m-6 p-3 cursor-pointer">
            <h5>Card link to blog 1</h5>
            <p>Lorem ipsum</p>
          </div>

          <div className="md:w-1/3 rounded shadow-lg m-6 p-3 cursor-pointer">
            <h5>Card link to blog 2</h5>
            <p>Lorem ipsum</p>
          </div>

          <div className="md:w-1/3 rounded shadow-lg m-6 p-3 cursor-pointer">
            <h5>Card link to blog 3</h5>
            <p>Lorem ipsum</p>
          </div>
        </div>
      </section>

      <section>
        <Testimonials />
      </section>
    </Layout>
  );
}

export default AboutPage;
