import React from 'react'

function Testimonials() {
  const testimonials = [
    {
      avatar: 'tony_lenzi.jpeg',
      Content: () => (
        <p>
          Tony is a passionate software developer who focuses not only on solving tough engineering
          challenges presented by working with big data, but also on doing so in a way that solves 
          his client's problems. When I worked with Tony, I routinely relied on him to deliver quick
          turn around solutions for client demos and pitches. His deep understanding of technologies
          from SOLR, to Python, to jQuery, meant I could trust him to deliver solid, reliable, and
          maintainable solutions.
        </p>
      ),
      person: 'Tony Lenzi'
    },
    {
      avatar: 'tom_gullo.jpeg',
      Content: () => (
        <p>
          Tony has been a thought leader in the software development world for over a decade. Many
          years ago, when large, cumbersome technologies and frameworks were popular, Tony was an
          advocate lighter, easier to use and maintain technologies. I worked on a project with
          Tony where we helped the client move towards a service oriented architecture, the type
          that is the norm today. Tony would be an invaluable asset to any organization that is
          looking to gain an edge through the smart implementation of technology.
        </p>
      ),
      person: 'Tom Gullo'
    },
  ]

  return (
    <>
      <div className="flex flex-wrap">
        {testimonials.map(({ avatar, Content, person }, index) => (
          <div className="lg:w-1/2 w-full p-3">
            <div className="flex sm:flex-row flex-col sm:items-start items-center bg-white rounded shadow p-6">
              <div className="rounded-full overflow-hidden mr-0 flex-none h-32 w-32 sm:h-20 sm:w-20 sm:mr-12">
                <img alt="{person}" src={require(`../images/${avatar}`)} />
              </div>
              <div className="w-12 sm:mr-6 mr-auto flex-none">
                <svg
                  fill="#cbd5e0"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlinkHref="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1000 1000"
                  enableBackground="new 0 0 1000 1000"
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M778.9,848.5c55.5,0,105-17.1,147.1-50.8c42.5-34,64-81.3,64-140.5c0-71-23.7-123-70.5-154.4c-44.5-30-98.8-45.7-161.3-46.7c12-53.5,38.9-97.4,79.8-130.7c43.1-35,90.6-62.7,141.1-82.2l3.4-1.3v-90.4l-7,2.3c-49.6,16.5-99.9,37.9-149.5,63.5c-49.8,25.7-94.4,57.3-132.6,93.9c-38.2,36.6-69.9,78.7-94,125.4c-24.2,46.8-36.5,101.1-36.5,161.1c0,66.9,17.4,125.9,51.8,175.3C649.4,823.1,704.7,848.5,778.9,848.5z"/>
                    <path d="M61.8,773.1c34.8,50,90.1,75.4,164.3,75.4c55.5,0,105-17.1,147.1-50.8c42.5-34,64-81.3,64-140.5c0-71-23.7-123-70.5-154.4c-44.5-30-98.8-45.7-161.3-46.7c12-53.5,38.8-97.4,79.8-130.7c43.1-35,90.6-62.7,141.1-82.2l3.4-1.3v-90.4l-7,2.3c-49.6,16.5-99.9,37.9-149.5,63.5c-49.8,25.7-94.4,57.3-132.6,93.8c-38.2,36.6-69.9,78.7-94,125.4C22.3,483.4,10,537.7,10,597.7C10,664.6,27.4,723.6,61.8,773.1z"/>
                  </g>
                </svg>
              </div>
              <div className="text-gray-700">
                <Content />
                <h6 className="text-right mt-4 text-primary">{person}</h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}


export default Testimonials
